<template>
  <v-textarea
  v-model="inputVal"
    rows="2"
    :label='$t("model.prop.data")'
    key="data"
    :rules="rules"
  ></v-textarea>
</template>

<script>
export default {
   props: ["value"],
    data: () => ({
    data: null,
     rules : [v => !!v || 'Data is required'],
   
  }),
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>