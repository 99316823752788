<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="name"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="value"
      :min="minDate"
      color="primary"
      @input="menu2 = false"
      v-on:change="setValue"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  components: {
    //
  },
  computed: {
    //
  },
  props: ["name","formKey","callbackSelect","value"],
  data: () => ({
    menu2: false,
    date: moment().format("YYYY-MM-DD"),
    minDate: moment().format("YYYY-MM-DD"),
    menu: false,
  }),
 
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
    setValue(val){
       this.date = val;
      this.callbackSelect(this.date)
    }
  },

};
</script>