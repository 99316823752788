<template>
  <v-select
    :label='$t("model.prop.status")'
    class="white"
    :items="data"
    item-text="name"
    item-value="value"
    v-model="inputVal"
  ></v-select>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError","value"],
  data: () => ({
    data: [
      {
        name:'Running',
        value:'running'
      },
      {
        name:'Success',
        value:'success'
      },
      {
        name:'Stopped',
        value:'stopped'
      }
    ],
    id: null,
    item: null,
  }),
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
   
  },
  mounted() {
  },
};
</script>
